@use "../../../../variables" as variables;

ngx-slider {
  .ngx-slider-bar.ngx-slider-selection {
    background-color: variables.$primary;
  }

  .ngx-slider-span.ngx-slider-pointer {
    background-color: variables.$primary;
  }

  .ngx-slider-ticks .ngx-slider-tick.ngx-slider-selected {
    background-color: variables.$primary;
  }
}
