@use "../../../../../variables" as variables;

.ng-select {
  .ng-dropdown-panel .ng-option.ng-notfound {
    color: variables.$textMutedColor;
  }

  .ng-select-container {
    border-radius: 0.375rem;

    .ng-value-container .ng-input input {
      background-color: transparent !important;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: unset;
  }

  &.ng-select-single {
    .ng-select-container {
      height: 38px;

      .ng-arrow-wrapper {
        padding-top: 4px;
      }

      .ng-value-container {
        .ng-input {
          top: 7px;
          padding-left: 12px;
        }
      }
    }
  }

  & + [appProvideParentForm] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}

.has-error .ng-select,
.ng-select.app-invalid,
.ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    color: variables.$textDark;
    border-color: variables.$danger;
    border-width: 1px;
    border-style: dotted;
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: variables.$whiteSmoke;
      border-bottom-color: variables.$danger;
    }
  }
}

table.table-collection .item .ng-select .ng-select-container {
  border: 0;
  border-bottom: variables.$border-separator;
}
